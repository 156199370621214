import React, { useEffect } from 'react';
import { Transaction } from '../../core/domain/transaction/transaction';
import { BreakingErrors, QueryParams } from '../../core/enums/module';
import { Analytics, EventTypes, FormFields, Steps, stepToFormFieldRecord } from '../../core/services/analytics/module';
import { getJSXInnerText } from '../../utilities/getJSXInnerText';
import { getQueryParameters } from '../../utilities/getQueryParameter';
import { SupportPhoneLink } from '../SupportPhoneLink/SupportPhoneLink';
import { BreakingErrorMap } from './breakingErrorMap';
import styles from './ErrorPage.module.scss';

const getRootUrlWithoutProductId = (): string => {
	const queryParams = getQueryParameters();
	queryParams.delete(QueryParams.ProductId);
	return `/?${queryParams.toString()}`;
};

const ErrorPage = ({
	analyticsService = Analytics.Instance(),
	transaction = Transaction.Instance()
}) => {
	const activeBreakingError = transaction.Error.CurrentIssue as BreakingErrors;
	const errorBody = activeBreakingError ? BreakingErrorMap.get(activeBreakingError) : undefined;

	useEffect(() => {
		if (activeBreakingError) {
			analyticsService.TrackError({
				event_name: activeBreakingError,
				step: Steps.One,
				events: {
					error_text: getJSXInnerText(BreakingErrorMap.get(activeBreakingError)),
					error_type: EventTypes.Checkout,
					form_field: stepToFormFieldRecord[Steps.One] || FormFields.BeginCheckout
				}
			});
		}
	}, []);

	return (
		<div className={styles.main}>
			<div className={`container ${styles.message}`}>
				{errorBody ? (
					<>
						{errorBody}
						<p className={styles.questions}>
							Questions? Please call give us a call at <SupportPhoneLink /> and we would be happy to assist you.
						</p>
					</>
				) : (
					<p>
						We're sorry, there was a technical error and we were unable to process your purchase. If you need further assistance, give us a
						call at <SupportPhoneLink /> or try again later.
					</p>
				)}
				{<div className={styles.reloadCta}>
					<a href={getRootUrlWithoutProductId()}>Reload / try again</a>
				</div>}
				<a href="https://www.usconcealedcarry.com/" className={styles.back}>
					← Back to www.usconcealedcarry.com
				</a>
			</div>
		</div>
	);
};

export default ErrorPage;
