import React, { FunctionComponent, useEffect, useState } from 'react';
import { DismissibleErrors } from '../../core/enums/dismissibleErrors';
import { ITransaction } from '../../core/domain/transaction/ITransaction';
import { Transaction } from '../../core/domain/transaction/transaction';
import { Messages } from '../../core/enums/messages';
import { getJSXInnerText } from '../../utilities/getJSXInnerText';
import { DismissibleErrorMap } from './dismissibleErrorMap';
import { Analytics, EventTypes, FormFields, IAnalytics, Steps, stepToFormFieldRecord } from '../../core/services/analytics/module';
import styles from '../ErrorModal/ErrorModal.module.scss';

type Props = {
	analyticsService?: IAnalytics
	transaction?: ITransaction
};

const ErrorModal: FunctionComponent<Props> = ({
	analyticsService = Analytics.Instance(),
	transaction = Transaction.Instance()
}) => {
	const [error, setError] = useState<DismissibleErrors | null>(null);
	const [additionalDetails, setAdditionalDetails] = useState('');
	const errorObject = error ? DismissibleErrorMap.get(error) : undefined;

	const clearModal = () => {
		setError(null);
		setAdditionalDetails('');
	};

	useEffect(() => {
		transaction.Error.Subscribe((e) => {
			if (transaction.Message.CurrentIssue) {
				transaction.Message.Publish(Messages.Empty);
			}
			const errorType = typeof e === 'object' ? e.error : e;
			const isDismissibleError = Object.values(DismissibleErrors).includes(errorType as DismissibleErrors);
			setError(isDismissibleError ? (errorType as DismissibleErrors) : null);
			setAdditionalDetails(typeof e === 'object' ? e.additionalDetails : '');
		});
	}, []);

	useEffect(() => {
		if (error && error !== DismissibleErrors.CreditCardError) {
			analyticsService.TrackError({
				event_name: error,
				step: Steps.One,
				events: {
					error_text: getJSXInnerText(DismissibleErrorMap.get(error)?.body),
					error_type: EventTypes.Checkout,
					form_field: stepToFormFieldRecord[Steps.One] || FormFields.BeginCheckout
				}
			});
		}
	}, [error]);

	return (
		<>
			{!!errorObject && (
				<>
					<div className={styles.overlay}></div>
					<div className={styles.modal}>
						<div className={styles.heading}>
							<p>{errorObject.heading}</p>
						</div>
						<div className={styles.body}><>
							{!!additionalDetails && <p><strong>{additionalDetails}</strong></p>}
							{errorObject.body}
						</></div>
						<button id="errorModalOkayButton" onClick={clearModal} className={styles.button}>
							Continue
						</button>
					</div>
				</>
			)}
		</>
	);
};
export default ErrorModal;
