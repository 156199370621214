export enum SessionStorageKeys {
	PurchasedSubscription = 'checkoutPurchasedSubscription',
	TransactionData = 'checkoutTransactionData',
	LastKnownRoute = 'checkoutLastKnownRoute',
	UserOnCartAbandonmentPath = 'userOnCartAbandonmentPath',
	NewMemberPricePaid = 'checkoutNewMemberPricePaid',
	PtuWasShown = 'checkoutPtuWasShown',
	LastMessageShown = 'lastMessageShown',
	MemberStatusChecked = 'memberStatusChecked',
	AMACExtensionStatus = 'AMACExtensionStatus'
}
